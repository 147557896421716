import {
  ExternalLinkIcon,
  ThumbDownIcon,
  ThumbUpIcon,
} from "@heroicons/react/outline";
import { useContext } from "react";
import { GlobalCourseState } from "../../contexts/courses-context";

export const CoursesPage = () => {
  const { courses } = useContext(GlobalCourseState);
  return (
    <div className="mx-auto max-w-5xl bg-shark-800 min-h-full  p-2">
      <div className="text-xl border-b mb-2">Courses</div>
      <div className="grid gap-2  grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
        {(courses || []).map((course) => {
          return (
            <div
              className="rounded shadow-md border  shadow-shark-400 border-shark-400 flex-1  bg-shark-700  flex flex-col"
              key={course.id}
            >
              <div className="h-full w-full grid place-items-center border-b border-shark-400 aspect-square">
                <img
                  alt={course.name}
                  className="h-full w-full rounded-t"
                  src={course.img}
                />
              </div>
              <div className="capitalize p-2 border-b border-shark-400 ">
                {course.name}
              </div>
              <div className="flex gap-2 xl:gap-1 justify-between items-center px-2 py-1 bg-slate-700 rounded-b">
                <div className="flex gap-2 xl:gap-1 justify-between items-center text-xs whitespace-nowrap ">
                  <ThumbUpIcon className="h-4 w-4 xl:h-3 xl:w-3 stroke-shark-100 hover:stroke-shark-500" />
                  <ThumbDownIcon className="h-4 w-4 xl:h-3 xl:w-3 stroke-shark-100 hover:stroke-shark-500" />
                </div>
                <a
                  href={course.url}
                  target="_blank"
                  rel="noreferrer"
                  className="flex gap-2 xl:gap-1 justify-between items-center text-xs "
                >
                  website
                  <ExternalLinkIcon className="h-4 w-4 xl:h-3 xl:w-3" />
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
// Down by me:
// Gladstan in Salem. Hobble Creek in Springville canyon.
// SLC: South Mountain up in SL valley.
// Park City: Canyons
// Midway: Wasatch Mountain
