import { useContext } from "react";
import { useParams } from "react-router-dom";
import { GlobalEventsState } from "../../contexts/events-context";

import { generateUniqueKey } from "../../utils/generate-unique-key";

import { OBFYEvent } from "../events/obfy-event";

export const CurrentTournament = () => {
  const params = useParams<{ year: string }>();
  const { year } = params;
  const { events } = useContext(GlobalEventsState);

  const yearEvents = events?.[year || ""] || {};

  return (
    <div className="max-w-5xl mx-auto p-2 bg-shark-700 shadow-lg shadow-shark-900 min-h-full">
      <h1>Welcome, to The's Memorial {year}</h1>
      <h1>Aug, 26 - 28</h1>

      <section>
        {Object.keys(yearEvents).length ? (
          renderAllThings(yearEvents || {}, "")
        ) : (
          <div className="bg-shark-50  mx-auto text-shark-50 border-shark-50 bg-opacity-25 border-2 rounded-lg max-w-fit px-3 py-2">
            No Event Info for {year}
          </div>
        )}
      </section>
    </div>
  );
};

function renderAllThings(a: any, type?: string) {
  if (!a) return <div>No data</div>;
  if (Array.isArray(a)) {
    return a
      .sort((x: any, y): any => {
        if (y?.date && x?.date) {
          return new Date(x?.date).getTime() - new Date(y?.date).getTime();
        }
        return 0;
      })
      .map((content) => {
        return (
          <OBFYEvent
            key={generateUniqueKey("events.")}
            content={content}
            type={type}
          />
        );
      });
  }
  return Object.entries(a).map(([name, val]: [string, any]) => {
    return (
      <div key={generateUniqueKey("events.")}>
        <div className="text-lg uppercase mb-1">{name}</div>
        <div className="flex flex-wrap gap-2">{renderAllThings(val, name)}</div>
      </div>
    );
  });
}
