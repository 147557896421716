export function GolfCard(props: {
  course: string;
  courseId: number;
  date: string;
  id: number;
}) {
  try {
    const dateOptions: Intl.DateTimeFormatOptions = {
      dateStyle: "long",
      timeStyle: "short",
    };
    const intlDate = new Intl.DateTimeFormat("'en-US'", dateOptions);
    const d = new Date(props?.date);

    return (
      <div
        key={props?.id}
        className="border flex-1 border-shark-100 rounded-md bg-shark-200"
      >
        <div className="text-xl whitespace-nowrap px-4 py-2 uppercase border-b-2 bg-gradient-to-t border-shark-300 from-shark-300 to-transparent">
          {props?.course}
        </div>
        <div className="flex items-end gap-1 py-3 px-2 text-lg whitespace-nowrap">
          {intlDate.format(d)}
        </div>
      </div>
    );
  } catch (err) {
    return (
      <div
        key={props?.id}
        className="border flex-1 border-shark-100 rounded-md bg-shark-200"
      >
        <div className="text-xl whitespace-nowrap px-4 py-2 uppercase border-b-2 bg-gradient-to-t border-shark-300 from-shark-300 to-transparent">
          {props?.course}
        </div>
        <div className="flex items-end gap-1 py-3 px-2 text-lg whitespace-nowrap">
          {props?.date}
        </div>
      </div>
    );
  }
}
