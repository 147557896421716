import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import App from "../../App";
import { Layout } from "../layout/layout";
import { AllEvents } from "../pages/all-events";
import { CoursesPage } from "../pages/courses";
import { CurrentScores } from "../pages/current-score";
import { CurrentTournament } from "../pages/current-tournament";
import { NotFound } from "../pages/not-found";
import { PlayerPage } from "../pages/player-page";

export const SwitchRouter = (props: any) => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route key={1} path="/" element={<App />} />
          <Route key={1} path="/events/:year" element={<CurrentTournament />} />
          <Route key={1} path="/scores/:year" element={<CurrentScores />} />
          <Route path="/p/:id" element={<PlayerPage />} />
          <Route key={1} path="/courses" element={<CoursesPage />} />
          <Route key={1} path="/events" element={<AllEvents />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </Router>
  );
};
