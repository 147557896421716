import { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalContenderState } from "../../contexts/contenders-context";
import { GlobalStatState } from "../../contexts/stat-context";

import { generateUniqueKey } from "../../utils/generate-unique-key";
import { PlayerCard } from "../player-card/player-card";
// import fam from "../../resources/imgs/riggs-fam.png";
export const LandingPage = () => {
  const { contenders } = useContext(GlobalContenderState);
  const { stats } = useContext(GlobalStatState);
  return (
    <div className="p-4 grid grid-cols-1  gap-2">
      <section className="w-full border border-shark-900 max-w-5xl mx-auto [min-height:20em] grid place-items-center text-2xl rounded-md font-semibold text-center uppercase chad-background leading-tight">
        {/* <div>
          <div>
            Welcome to <i className="text-yellow-500">THE's</i> Golf Memorial
          </div>
          <div className="text-xl capitalize text-shark-600 outline bg-offWhite-600 rounded">
            Aug, 26-28 2022
          </div>
        </div> */}
        <div className="w-full bg-gradient-to-br from-yellow-500 to-yellow-800 py-5">
          <h1 className="text-3xl ">Thanks to everyone for a great weekend</h1>
          <div className="text-xl flex gap-1 justify-center font-mono italic">
            See you in 2023
          </div>
        </div>
      </section>
      <section className="w-full max-w-5xl mx-auto odd:rounded p-2 odd:bg-gradient-to-r odd:from-shark-300 odd:to-shark-100">
        <div className="text-center leading-10 uppercase text-4xl font-helvetica font-bold">
          Players
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-4 max-w-5xl mx-auto gap-2">
          {contenders.map((c) => {
            return (
              <Link key={generateUniqueKey("player")} to={`/p/${c.id}`}>
                <PlayerCard player={c} />
              </Link>
            );
          })}
        </div>
      </section>

      <section className="w-full max-w-5xl  mx-auto  odd:rounded p-2 odd:bg-gradient-to-r odd:from-shark-300 odd:to-shark-100">
        <div>
          <div>Current Leader</div>
          <div>
            <small>---</small>
          </div>
        </div>
        <div>
          <div>Scores</div>
          <div>
            <small>coming soon dur</small>
          </div>
        </div>
      </section>
      <section className="w-full max-w-5xl  mx-auto  odd:rounded  odd:bg-gradient-to-r odd:from-shark-300 odd:to-shark-100">
        <ul className="list-none bg-shark-500 flex flex-col rounded ">
          {(stats || []).map((stat) => {
            return (
              <li
                key={generateUniqueKey("stats")}
                className="first:rounded-t-md last:rounded-b-md p-1 text-shark-500 bg-offWhite-500 odd:bg-offWhite-700 "
              >
                <div
                  className="
                flex justify-between max-w-2xl mx-auto
                "
                >
                  <span>{stat.owner}</span>
                  <span>{stat.title}</span>
                  <span>{stat.value}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </section>
      <section className="w-full max-w-5xl flex flex-col gap-3 mx-auto  odd:rounded p-2 odd:bg-gradient-to-r odd:from-shark-300 odd:to-shark-100">
        <div>Start getting ideas for 2023! </div>
        <ul>
          <li>Pickle Ball</li>
          <li>Golf</li>
          <li>Tennis</li>
          <li>BBQ</li>
          <li>NGFL</li>
        </ul>
        {/* <div>
          Locations
          <div>Payson, UT</div>
          <div>Midway, UT</div>
        </div>
        <div>
          Dates/Times
          <div> - Gladstan August 26, 2022 2:15 PM </div>
          <div> - Soldier Hollow August 27, 2022 9:00 AM </div>
          <div> - Wasatch Mountain August 26, 2022 3:12 PM </div>
        </div>
        <div>
          <Link
            to={`/events/${new Date().getFullYear()}`}
            className="px-3 py-1 rounded bg-shark-500 text-slate-200 border-slate-200 hover:underline max-w-fit border "
          >
            More Info
          </Link>
        </div> */}
      </section>
    </div>
  );
};
