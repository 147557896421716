import { useParams } from "react-router-dom";

export const CurrentScores = () => {
  const { year } = useParams();
  return (
    <div className="max-w-7xl mx-auto  p-2 bg-shark-700 shadow-lg shadow-shark-900 rounded min-h-full">
      <h1>Scores: {year}</h1>
    </div>
  );
};
