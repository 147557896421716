import React, {
  createContext,
  FC,
  useEffect,
  useReducer,
  useState,
} from "react";
import { LoadingPage } from "../components/loading/loading";

import { getItem } from "../utils/get-item";
import { getAllStats } from "../utils/get-stats";
import { setItem } from "../utils/set-item";

export interface Stat {
  title: string;
  value: number | string;
  owner: string;
  witness?: string;
}

export interface IStatState {
  stats: Stat[];
}
const initialState: IStatState = {
  stats: [],
};
export const GlobalStatState = createContext<IStatState>({} as IStatState);
export const GlobalStatDispatch = createContext<React.Dispatch<any>>(
  {} as React.Dispatch<any>
);

const STATS_KEY = "OBFY_STATS";

const statsActions = {
  SET_STATS: "SET_STATS",
  UPDATE_STATS: "UPDATE_STATS",
};

function statsReducer(
  state: IStatState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case statsActions.SET_STATS:
      setItem(STATS_KEY, JSON.stringify(action.payload));
      return { stats: action.payload };
    default:
      return state;
  }
}

export const GlobalStatsProvider: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(
    statsReducer,
    getItem(STATS_KEY) || initialState
  );

  useEffect(() => {
    async function loadStats() {
      const stats = await getAllStats();

      dispatch({
        type: statsActions.SET_STATS,
        payload: stats,
      });
      setLoading(false);
    }
    loadStats();
  }, []);
  if (loading) return <LoadingPage />;
  return (
    <GlobalStatState.Provider value={state}>
      <GlobalStatDispatch.Provider value={dispatch}>
        {children}
      </GlobalStatDispatch.Provider>
    </GlobalStatState.Provider>
  );
};
