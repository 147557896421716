import { get, ref } from "firebase/database";
import { Player } from "../components/player-card/player-card";
import { FirebaseApp } from "../firebase/firebase";
const { db } = FirebaseApp;

export async function getAllStats() {
  const resp = await get(ref(db, "/stats"));
  const json = resp.val();
  const contenders = Object.values(json);
  return contenders;
}

export async function getPlayerById(id: string): Promise<Player> {
  const resp = await get(ref(db, `/stats/${id}`));
  const player = resp.val();
  return player;
}

// async function addArrToFB(arr: any[], refString: string) {
//   const { db } = FirebaseApp;
//   await set(ref(db, refString), arr);
// }
