import { Link, useNavigate } from "react-router-dom";

export function NotFound() {
  const navigate = useNavigate();
  return (
    <div className="min-h-screen grid place-items-center font-thin ">
      <div className="mx-auto max-w-4xl ">
        <div className="flex gap-1">
          <div className="text-2xl  whitespace-nowrap border-r max-w-fit pr-2 border-red-500">
            Page Not Found
          </div>
          <div className="text-2xl font-bold max-w-fit pl-2">404</div>
        </div>
        <div className=" flex justify-between gap-2 items-center">
          <Link to={"/"} className="hover:underline">
            Home →
          </Link>
          <div onClick={() => navigate(-1)} className="hover:underline">
            Back ←
          </div>
        </div>
      </div>
    </div>
  );
}
