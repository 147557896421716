import { Content } from "./content/content";
import { Header } from "./header/header";
import { SubHeader } from "./sub-header/sub-header";

interface Props {
  children: any;
}

export const Layout = (props: Props) => {
  return (
    <div className="text-neutral-200 bg-gradient-to-br from-neutral-800 to-neutral-900 dark:bg-neutral-800 min-h-screen">
      <Header />
      <SubHeader />
      <Content>{props.children}</Content>
    </div>
  );
};
