import { get, ref } from "firebase/database";
import { Events } from "../contexts/events-context";

import { FirebaseApp } from "../firebase/firebase";

export async function getAllEvents() {
  const { db } = FirebaseApp;
  const resp = await get(ref(db, "/events"));
  const events = resp.val();

  return events;
}

export async function getEventByYear(year: string): Promise<Events> {
  const { db } = FirebaseApp;
  const resp = await get(ref(db, `/events/${year}`));
  const event = resp.val();

  return event;
}
