import { FC } from "react";
import { Footer } from "../footer/footer";

export const Content: FC<any> = (props: any) => {
  return (
    <div className="absolute top-16 left-0 right-0 bottom-0 overflow-auto">
      {props.children}
      <Footer />
    </div>
  );
};
