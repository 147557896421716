import React, {
  createContext,
  FC,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Player } from "../components/player-card/player-card";

import { getItem } from "../utils/get-item";

import { getAllContenders } from "../utils/get-contenders";
import { LoadingPage } from "../components/loading/loading";
interface IContendersState {
  contenders: Player[];
}
const initialState: IContendersState = {
  contenders: [],
};
export const GlobalContenderState = createContext<IContendersState>(
  {} as IContendersState
);
export const GlobalContenderDispatch = createContext<React.Dispatch<any>>(
  {} as React.Dispatch<any>
);

const CONTENDERS_KEY = "OBFY_CONTENDERS";

export const contendersActions = {
  SET_CONTENDERS: "SET_CONTENDERS",
  UPDATE_CONTENDERS: "UPDATE_CONTENDERS",
};

function contendersReducer(
  state: IContendersState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case contendersActions.SET_CONTENDERS:
      return { contenders: action.payload };
    default:
      return state;
  }
}

export const GlobalContendersProvider: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [state, dispatch] = useReducer(
    contendersReducer,
    getItem(CONTENDERS_KEY) || initialState
  );

  useEffect(() => {
    async function loadContenders() {
      const contenders = await getAllContenders();

      dispatch({
        type: contendersActions.SET_CONTENDERS,
        payload: contenders,
      });
      setLoading(false);
    }
    loadContenders();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <GlobalContenderState.Provider value={state}>
      <GlobalContenderDispatch.Provider value={dispatch}>
        {children}
      </GlobalContenderDispatch.Provider>
    </GlobalContenderState.Provider>
  );
};
