import { Link } from "react-router-dom";
// import { TemperatureInfo } from "../../temperature/temperature";
import logo from "../../../resources/imgs/nav-logo.png";

export const Header = () => {
  return (
    <header className="h-10 text-xs shadow bg-gradient-to-br from-stone-700 to-shark-600 absolute top-0 left-0 right-0 flex items-center justify-between gap-2 px-2">
      <Link to={"/"}>
        <img
          className={`h-10 w-10 rotate-45 hover:-rotate-180 transition-all duration-500`}
          src={logo}
          alt="Beetle"
        />
      </Link>
      <div className="flex items-center gap-2">
        {/* <TemperatureInfo /> */}
        <Link
          to={`/events/${new Date().getFullYear()}`}
          className="hover:underline uppercase"
        >
          {new Date().getFullYear()} Info
        </Link>
        <Link to={`/courses`} className="hover:underline uppercase">
          Courses
        </Link>
        {/* <Link to="/scores/2022" className="hover:underline uppercase">
          Scores
        </Link>
        <Link to="/locations/2022" className="hover:underline uppercase">
          Locations
        </Link> */}
      </div>
    </header>
  );
};
