import { get, ref } from "firebase/database";

import { FirebaseApp } from "../firebase/firebase";
const { db } = FirebaseApp;

export async function getAllCourses() {
  const resp = await get(ref(db, "/courses"));
  const json = resp.val();

  return json;
}
