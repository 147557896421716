import { generateUniqueKey } from "../../utils/generate-unique-key";
import { GolfCard } from "./golf-event";

export function OBFYEvent(props: { type: string | undefined; content: any }) {
  switch (props.type) {
    case "golf":
    case "GOLF":
    case "Golf":
      return (
        <GolfCard key={generateUniqueKey(props.type)} {...props.content} />
      );
    default:
      return (
        <>
          <div key={generateUniqueKey("type")}>
            <h1>Need a card for {props.type}</h1>
            <h1>{JSON.stringify(props.content)}</h1>
          </div>
        </>
      );
  }
}
