import { get, ref } from "firebase/database";
import { Player } from "../components/player-card/player-card";
import { FirebaseApp } from "../firebase/firebase";

export async function getAllContenders() {
  const { db } = FirebaseApp;
  const resp = await get(ref(db, "/contenders"));
  const json = resp.val();
  const contenders = Object.values(json);
  return contenders;
}

export async function getPlayerById(id: string): Promise<Player> {
  const { db } = FirebaseApp;
  // console.log("getting", id);
  const resp = await get(ref(db, `/contenders/${id}`));
  const player = resp.val();

  return player;
}
