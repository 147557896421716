import { LandingPage } from "./components/pages/landing";

function App() {
  return (
    <div>
      <LandingPage />
    </div>
  );
}

export default App;
