import { FC } from "react";
import { GlobalContendersProvider } from "./contenders-context";
import { GlobalCoursesProvider } from "./courses-context";
import { GlobalEventsProvider } from "./events-context";
import { GlobalStatsProvider } from "./stat-context";
import { GlobalThemeProvider } from "./theme-context";

export const ContextWrapper: FC<any> = ({ children }) => {
  return (
    <GlobalContendersProvider>
      <GlobalCoursesProvider>
        <GlobalEventsProvider>
          <GlobalStatsProvider>
            <GlobalThemeProvider>{children}</GlobalThemeProvider>
          </GlobalStatsProvider>
        </GlobalEventsProvider>
      </GlobalCoursesProvider>
    </GlobalContendersProvider>
  );
};
