import React, {
  createContext,
  FC,
  useEffect,
  useReducer,
  useState,
} from "react";

import { getItem } from "../utils/get-item";
import { setItem } from "../utils/set-item";
import { LoadingPage } from "../components/loading/loading";
import { getAllCourses } from "../utils/get-courses";

export interface ICourse {
  id: string;
  url: string;
  name: string;
  img: string;
}

export interface ICourseState {
  courses: ICourse[];
}
const initialState: ICourseState = {
  courses: [],
};
export const GlobalCourseState = createContext<ICourseState>(
  {} as ICourseState
);
export const GlobalCourseDispatch = createContext<React.Dispatch<any>>(
  {} as React.Dispatch<any>
);

const COURSES_KEY = "COURSES_KEY";

const coursesActions = {
  SET_COURSES: "SET_COURSES",
  UPDATE_COURSES: "UPDATE_COURSES",
};

function statsReducer(
  state: ICourseState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case coursesActions.SET_COURSES:
      setItem(COURSES_KEY, JSON.stringify(action.payload));
      return { courses: action.payload };
    default:
      return state;
  }
}

export const GlobalCoursesProvider: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [state, dispatch] = useReducer(
    statsReducer,
    getItem(COURSES_KEY) || initialState
  );

  useEffect(() => {
    async function loadCourses() {
      const stats = await getAllCourses();

      dispatch({
        type: coursesActions.SET_COURSES,
        payload: stats,
      });
      setLoading(false);
    }
    loadCourses();
  }, []);
  if (loading) return <LoadingPage />;
  return (
    <GlobalCourseState.Provider value={state}>
      <GlobalCourseDispatch.Provider value={dispatch}>
        {children}
      </GlobalCourseDispatch.Provider>
    </GlobalCourseState.Provider>
  );
};
