import React, {
  createContext,
  FC,
  useEffect,
  useReducer,
  useState,
} from "react";

import { getItem } from "../utils/get-item";

import { getAllEvents } from "../utils/get-events";
import { LoadingPage } from "../components/loading/loading";

export interface Events {
  [key: string]: any[];
}

interface IEventsState {
  events: { [key: string]: Events[] };
}
const initialState: IEventsState = {
  events: {},
};
export const GlobalEventsState = createContext<IEventsState>(
  {} as IEventsState
);
export const GlobalEventsDispatch = createContext<React.Dispatch<any>>(
  {} as React.Dispatch<any>
);

const EVENTS_KEY = "OBFY_EVENTS";

export const eventsActions = {
  SET_EVENTS: "SET_EVENTS",
};

function eventsReducer(
  state: IEventsState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case eventsActions.SET_EVENTS:
      return { events: action.payload };
    default:
      return state;
  }
}

export const GlobalEventsProvider: FC<any> = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [state, dispatch] = useReducer(
    eventsReducer,
    getItem(EVENTS_KEY) || initialState
  );

  useEffect(() => {
    async function loadEvents() {
      const events = await getAllEvents();

      dispatch({
        type: eventsActions.SET_EVENTS,
        payload: events,
      });
      setLoading(false);
    }
    loadEvents();
  }, []);
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <GlobalEventsState.Provider value={state}>
      <GlobalEventsDispatch.Provider value={dispatch}>
        {children}
      </GlobalEventsDispatch.Provider>
    </GlobalEventsState.Provider>
  );
};
