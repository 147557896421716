// import { MoonIcon, SunIcon } from "@heroicons/react/outline";
import React, { createContext, FC, useReducer } from "react";
import { getItem } from "../utils/get-item";
import { setItem } from "../utils/set-item";

export const GlobalThemeState = createContext<ThemeEnum>("dark");
export const GlobalThemeDispatch = createContext<React.Dispatch<any>>(
  {} as React.Dispatch<any>
);

const THEME_KEY = "OBFY_THEME";

export const themeActions = {
  SET_THEME: "SET_THEME",
};
type ThemeEnum = "dark" | "light";

function themeReducer(
  state: ThemeEnum,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case themeActions.SET_THEME:
      setItem(THEME_KEY, action.payload);
      return action.payload;
    default:
      return state;
  }
}

export const GlobalThemeProvider: FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(
    themeReducer,
    getItem(THEME_KEY) || "dark"
  );
  // const isDark = state === "dark";
  return (
    <GlobalThemeState.Provider value={state}>
      <GlobalThemeDispatch.Provider value={dispatch}>
        {children}
        {/* <div
          onClick={() => {
            dispatch({
              type: themeActions.SET_THEME,
              payload: isDark ? "light" : "dark",
            });
          }}
          className="fixed bottom-5 right-5 rounded-full p-3 bg-shark-50"
        >
          {isDark ? (
            <SunIcon className="h-7 w-7 rounded-full stroke-shark-500" />
          ) : (
            <MoonIcon className="h-7 w-7 rounded-full stroke-shark-500" />
          )}
        </div> */}
      </GlobalThemeDispatch.Provider>
    </GlobalThemeState.Provider>
  );
};
