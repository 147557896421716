import { useContext } from "react";
import { Link } from "react-router-dom";
import { GlobalEventsState } from "../../contexts/events-context";

export const AllEvents = () => {
  const { events } = useContext(GlobalEventsState);
  return (
    <div className="max-w-5xl mx-auto  p-2 bg-shark-700 shadow-lg shadow-shark-900 rounded min-h-full">
      <div className="uppercase text-2xl">Memories</div>
      {Object.entries(events).map(([k, v]) => (
        <div className="max-w-fit" key={k}>
          <Link className="cursor-pointer " to={`/events/${k}/`}>
            <div className="border-2 rounded  p-2 border-shark-100 bg-shark-200">
              <div className="uppercase px-1 text-md flex gap-2">
                <div>{k}</div>
                <div>events</div>
              </div>
              <div>
                {Object.keys(v).map((a) => (
                  <div className="px-2 uppercase text-sm" key={a}>
                    {a}
                  </div>
                ))}
              </div>
            </div>
          </Link>
        </div>
      ))}
    </div>
  );
};
