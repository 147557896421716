import { ChevronDownIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { getItem } from "../../utils/get-item";
import { setItem } from "../../utils/set-item";

function createWeatherRequestURL(lat?: string, lon?: string): string {
  const API_KEY = process.env.REACT_APP_WEATHER_API;
  const url = `https://api.openweathermap.org/data/2.5/onecall?lat=${
    lat || "40.760"
  }&lon=${lon || "-111.891"}&units=imperial&appid=${API_KEY}`;
  return url;
}

export const TemperatureInfo = () => {
  const [result, setResult] = useState<any>(null);
  const [showDaily, setShowDaily] = useState<any>(false);
  useEffect(() => {
    async function getTempInfo() {
      let lat = getItem("lat");
      let long = getItem("long");
      let exp = getItem("weather-exp");
      const now = Date.now();
      const fiveDays = 60 * 60 * 24 * 5;
      const expNum = Number(exp);
      const expired = now - expNum > fiveDays;
      if (!(lat && long) && expired) {
        navigator?.geolocation?.getCurrentPosition(
          function (position) {
            lat = position?.coords?.latitude;
            long = position?.coords?.longitude;
            setItem("lat", String(lat));
            setItem("long", String(long));
          },
          (err) => {
            console.warn(`ERROR(${err.code}): ${err.message}`);
          }
        );
      }
      const req = await fetch(createWeatherRequestURL(lat, long));
      const body = await req.json();

      setItem("weather-exp", Date.now());

      setResult(body);
    }
    getTempInfo();
  }, []);
  if (!result) return <div></div>;
  const current = result?.current || {};
  const daily = result?.daily || [];
  const weather = current?.weather[0] || {};
  return (
    <>
      <div className="max-w-5xl mx-auto flex gap-1 justify-around items-center px-1  h-6">
        <div className="flex items-end gap-1">
          <div className="font-light text-xs">Now</div>
          <div className="font-bold text-xs uppercase">{weather?.main}</div>
        </div>
        <div
          onClick={() => (showDaily ? setShowDaily(false) : setShowDaily(true))}
          className="text-xs flex gap-1 cursor-pointer hover:underline group"
        >
          <ChevronDownIcon
            className={`h-3 w-3 group-hover:stroke-blue-600 transition-all duration-300 ${
              showDaily ? "rotate-180" : "rotate-0"
            }`}
          />
          <small>{showDaily ? "less" : "more"}</small>
        </div>
        <div className="flex gap-1">
          <span className="text-xs flex gap-1 ">
            <small className="">Temp</small>
            {Number(current?.temp || 0).toFixed()}°F
          </span>

          <span className="text-xs flex gap-1 ">
            <small className="">Wind</small>
            {Number(current?.wind_speed || 0).toFixed()}mph
          </span>
        </div>
      </div>

      {showDaily ? (
        <div
          className={`bg-blue-900 relative z-50 transition-all duration-300 ${
            showDaily ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="max-w-5xl py-2 mx-auto flex gap-1 justify-center items-center  flex-wrap">
            {daily.map((day: any, i: number) => {
              return (
                <div
                  className="flex flex-col border-l pl-1 flex-1"
                  key={day.dt}
                >
                  <small className="text-xs">
                    {new Date(day.dt * 1000).toLocaleDateString()}
                  </small>
                  <div className="flex gap-2">
                    <span>↑{Number(day.temp.max).toFixed()}°</span>
                    <span>↓{Number(day.temp.min).toFixed()}°</span>
                    {/* <small>°F</small> */}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};
