import { Link } from "react-router-dom";
import { InstagramIcon } from "../../../assets/icons/ig";

export function Footer() {
  const latestYear = new Date().getFullYear();
  return (
    <div className=" bg-shark-100 py-3">
      <div className="flex mx-auto  max-w-5xl gap-2 items-center justify-around">
        <div className="flex flex-col gap-2">
          <Link to={`/events/${latestYear}`}>{latestYear} Info</Link>
        </div>
        <div className="flex flex-col gap-2">
          <InstagramIcon />
        </div>
        <div className="flex flex-col gap-2">
          <Link to={"/events"}>History</Link>
          <Link to={"/courses"}>Courses</Link>
          <Link to={"/scores"}>Scores</Link>
          {/* <Link to={"/games/"}>Games</Link> */}
        </div>
      </div>
    </div>
  );
}
